
.animationSlideDownAndUp {
    animation: slideDownAndUp 0.5s cubic-bezier(0.12, 0.42, 0.65, 0.95);
}

@keyframes slideDownAndUp {
    0% {
        transform: translateY(0)
    }
    50% {
        transform: translateY(300px)
    }
    100% {
        transform: translateY(0)
    }
}
.footer .MuiBadge-badge{
    transform: scale(1) translate(5%, -30%);
    background-color: #d11010;
    color: white;
}
.messageSeparator{
    width: 520px;
    overflow-x: visible;
}
.messageSeparator::before{
    height: 2px;
}
.messageSeparator::after{
    height: 2px;
}
.risposta {
    max-width: 400px;
}
.richiesta {
    max-width: 450px;
}



